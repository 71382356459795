.c- {
    &card {
        border: 5px solid #448bff;
        overflow: hidden;
        border-radius: 15px;
        background-color: rgba($color: $white, $alpha: 0.7);
    }
    &skewCard{
        position: relative;
        &::before{
            content: "";
            width: 95%;
            height: 100%;
            background-color: #0070ff;
            position: absolute;
            transform: translate(-50%,-50%) rotate(-5deg) skew(-5deg);
            top: 50%;
            left: 50%;
        }
        &-content{
            position: relative;
            background-color: $white;
            border: 5px solid #0070ff;
            &-title{
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                bottom: -2rem;
            }
        }

    }
}