// vendor 第三方元件修改
.slick-dots {
    i {
        color: $white;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        font-size: $font-20;
    }

    .fa-caret-left {
        left: 0;
    }

    .fa-caret-right {
        right: 0;
    }

    li {
        margin: 0 2px;
    }

    li button:before {
        opacity: 1;
        color: $white;
    }

    li.slick-active button:before {
        opacity: 1;
        color: #0095f5;
    }
}
.slick-prev,.slick-next{
    z-index: 5;
}
.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: $font-20;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -5%;
}

.slick-next {
    right: -5%;
}

.slick-prev:before {
    content: '◀';
}

.slick-next:before {
    content: '▶';
}

.p-index-product-img {
    img {
        transition: all ease-in-out 0.5s;
    }
}

.slick-current {
    .p-index-product-img {
        img {
            transform: scale(1.3);
        }
    }
}

.slick-cloned.slick-center{
    .p-index-product-img {
        img {
            transform: scale(1.3);
        }
    }
}
.slick-slide.slick-cloned.js-active{
    .p-index-product-img {
        img {
            @include max-lg{
                transform: scale(1.3);
            }
        }
    }
}

