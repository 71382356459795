.c-btn-arrow {
  margin: 0 auto;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 2px solid $primary;
  position: relative;
  transition: background-color ease-in-out 0.3s;

  &::before {
    content: "";
    border-width: 2px;
    border-style: solid;
    border-color: transparent transparent $primary $primary;
    width: 26px;
    height: 26px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    top: 50%;
    left: 50%;
    transition: all ease-in-out 0.3s;
  }

  &::after {
    content: "";
    width: 36px;
    height: 2px;
    background-color: $primary;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: all ease-in-out 0.3s;
  }

  &:hover {
    background-color: $primary;

    &::before {
      border-color: transparent transparent $white $white;
    }

    &::after {
      background-color: $white;
    }
  }
}

.c-btn-modal {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: $yellow;
  position: relative;
  font-size: 4rem;
  font-weight: 700;
  line-height: 95px;
  text-align: center;
  // cursor: pointer;
  transition: transform ease-in-out 0.3s;

  &:hover {
    transform: scale(1.1);
  }
}

.c-btn-kv {
  font-weight: 700;
  font-size: $font-26;
  color: $secondary;
  letter-spacing: 4px;
  background-color: $yellow;
  border-radius: 50px;
  padding: 0.3rem 1.5rem 0.3rem 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  box-shadow: 0px 8px 0px $secondary;
  transition: all ease-in-out 0.3s;
  position: relative;
  z-index: 5;

  &:hover {
    transform: scale(1.1);
  }

  &-icon {
    margin-right: 0.5rem;

    img {
      width: 45px;
    }
  }
}

.c-btn-scroll {
  color: $yellow;
  letter-spacing: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -60px;
  // margin-bottom: 5rem;
  @include min-sm {
    top: auto;
  }

  &:hover {
    color: $yellow;
  }

  &-arrow {
    width: 35px;
    // height: 35px;
    height: 15px;
    display: block;
    position: relative;
    animation-name: scroll;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    &-ontolo {
      width: 35px;
      height: 35px;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;

      &::before {
        content: "";
        width: 35px;
        height: 35px;
        border-width: 3px;
        border-style: solid;
        border-color: transparent transparent $yellow $yellow;
        transform: rotate(-45deg);
        position: absolute;
      }
    }
  }
}

.c-btn-download {
  font-weight: 900;
  font-size: $font-20;
  color: $secondary;
  background-color: $yellow;
  border-radius: 50px;
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  box-shadow: 0px 8px 0px $secondary;
  transition: all ease-in-out 0.3s;
  position: relative;
  top: -2.5rem;

  @include min-sm {
    font-size: $font-26;
  }

  &:hover {
    transform: scale(1.1);
  }

  &-icon {
    width: 40px;
    height: 40px;
    background-color: $white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
    }

    @include min-sm {
      width: 60px;
      height: 60px;
    }
  }

}

.c-btn-music {
  display: flex;
  justify-content: center;
  align-items: center;

  &-arrow {
    line-height: 0;
    margin-right: 5px;

    img {
      width: 35px;
    }
  }

  &-line {
    width: 100%;
    height: 1px;
    background-color: $secondary;
  }
}

.c-btn-float {
  position: fixed;
  right: 0;
  bottom: 3%;
  z-index: 990;
  opacity: 0;
  transition: all ease-in-out 0.3s;

  img {
    width: 110px;

    @include min-sm {
      width: 160px;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  &.js-show {
    opacity: 1;
  }
}