// 針對 html 標籤做基本設定
html {
  height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;500;700;900&display=swap');

// 思源黑體
body {
  font-family: 'Noto Sans TC', sans-serif;
  height: 100%;
}
//禁止選中畫面物件
body{
  // -webkit-user-select: none;
  // -webkit-touch-callout: none;
  // -moz-user-select: none;
}
//禁止圖片觸發互動
img{
  pointer-events: none;
}
ul {
  list-style: none;
  padding-left: 0;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(rgb(0, 151, 252), 0.3);

  @include min-md {
    -webkit-tap-highlight-color: transparent;
  }

  &:focus {
    outline: 0;
  }
}

a {
  -webkit-tap-highlight-color: rgba(rgb(0, 151, 252), 0.3);

  @include min-md {
    -webkit-tap-highlight-color: transparent;
  }

  &:hover {
    text-decoration: none;
  }
}

#wrapper {
  overflow-x: hidden;
}

// *{
//   border: 1px solid red;
// }