//page .p-{className}
.p- {
    &products {
        &-wrapper {
            background-image: url("../img/products/bg_ice.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 100%;
            padding-top: calc(60px + 1rem);
            @include min-sm {
                padding-top: calc(80px + 1rem);
            }
            @include min-md {
                padding-top: calc(100px + 1rem);
            }
        }

        &-list {
            &-classTitle {
                text-align: center;

                img {
                    width: 450px;
                }
            }

            &-title {
                text-align: center;
                width: fit-content;
                margin: 0 auto;

                h3 {
                    font-weight: 700;
                    color: $white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: auto;
                    height: 100%;
                    margin-bottom: 0;
                    font-size: $font-24;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;

                    @include min-md {
                        font-size: $font-26;
                    }
                }
            }

            &-img {
                // overflow: hidden;
                margin-bottom: 1rem;

                img {
                    transition: transform ease-in-out 0.3s;

                    &:hover {
                        transform: scale(1.1) rotateZ(15deg);
                    }
                }
            }
        }

        &-inpage {
            &-title {
                color: $primary;
                margin-bottom: 1rem;

                h3 {
                    font-size: $font-32;
                    font-weight: 700;
                    padding-bottom: 0.5rem;
                    border-bottom: 1px solid $primary;

                    @include min-md {
                        font-size: $font-46;
                        display: inline-block;
                    }
                }

                p {
                    font-weight: 700;

                    @include min-md {
                        font-size: $font-24;
                    }
                }
            }

            &-tag {
                background-color: $primary;
                font-weight: 700;
                color: $white;
                width: 130px;

                @include min-md {
                    width: 200px;
                }

                h4 {
                    text-align: center;
                    font-size: $font-16;

                    @include min-md {
                        width: 200px;
                        font-size: $font-22;
                    }
                }
            }

            &-content {
                margin-bottom: 1rem;
            }

            &-dealer {
                width: 50px;
                margin-top:0.5rem;
                margin-bottom:0.5rem;
            }
        }
    }
}