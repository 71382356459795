//Color
// 固定顏色
$white: #fff;
$black: #000;

$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$yellow:#ffec08;

// ------ 自定義色系

// 主題色 (依照專案自行設定)
$primary: #28abf8;
$secondary: #0044a0;
$thrid:#0070ff;

// 若有使用驗證等功能請務必開啟下列顏色
// $success: #28a745;
// $danger: #dc3545;
// $warning: #f9a825;
// $info: #17a2b8;

$color-theme: ("white": $white,
  "black": $black,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
) !default;


// 斷點 對應到breakpoint mixin 、 u-m-{間距} 、 u-p-{間距}
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$grid-breakpoints: (xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// ------ 全網字級定義
$font-12: 0.75rem;
$font-14: 0.875rem;
$font-16: 1rem;
$font-18: 1.125rem;
$font-20: 1.25rem;
$font-22: 1.375rem;
$font-24: 1.5rem;
$font-26: 1.625rem;
$font-28: 1.75rem;
$font-30: 1.875rem;
$font-32: 2rem;
$font-34: 2.125rem;
$font-36: 2.25rem;
$font-38: 2.375rem;
$font-40: 2.5rem;
$font-42: 2.625rem;
$font-44: 2.75rem;
$font-46: 2.875rem;
$font-48: 3rem;
$font-50: 3.125rem;
$font-52: 3.25rem;

// 對應到 u-font-{大小}
$font-size-theme: ("font-14": $font-14,
  "font-16": $font-16,
  "font-18": $font-18,
  "font-20": $font-20,
  "font-22": $font-22,
  "font-24": $font-24,
  "font-26": $font-26,
  "font-28": $font-28,
  "font-30": $font-30,
  "font-32": $font-32,
  "font-34": $font-34,
  "font-36": $font-36,
  "font-38": $font-38,
  "font-40": $font-40,
  "font-42": $font-42,
  "font-44": $font-44,
  "font-46": $font-46,
  "font-48": $font-48,
  "font-50": $font-50,
  "font-52": $font-52,
) !default;

//------間距
$spacing-000: 0rem;
$spacing-025: 0.25rem;
$spacing-050: 0.5rem;
$spacing-075: 0.75rem;
$spacing-100: 1rem;
$spacing-125: 1.25rem;
$spacing-150: 1.5rem;
$spacing-175: 1.75rem;
$spacing-200: 2rem;
$spacing-225: 2.25rem;
$spacing-250: 2.5rem;
$spacing-275: 2.75rem;
$spacing-300: 3rem;
$spacing-325: 3.25rem;
$spacing-350: 3.5rem;
$spacing-375: 3.75rem;
$spacing-400: 4rem;
$spacing-425: 4.25rem;
$spacing-450: 4.5rem;
$spacing-475: 4.75rem;
$spacing-500: 5rem;
$spacing-525: 5.25rem;
$spacing-550: 5.5rem;
$spacing-575: 5.75rem;
$spacing-600: 6rem;
$spacing-625: 6.25rem;
$spacing-650: 6.5rem;
$spacing-675: 6.75rem;
$spacing-700: 7rem;
$spacing-725: 7.25rem;
$spacing-750: 7.5rem;
$spacing-775: 7.75rem;
$spacing-800: 8rem;

// u-m-{間距} 、 u-p-{間距}
$spacings: ("000": $spacing-000,
  "025": $spacing-025,
  "050": $spacing-050,
  "075": $spacing-075,
  "100": $spacing-100,
  "125": $spacing-125,
  "150": $spacing-150,
  "175": $spacing-175,
  "200": $spacing-200,
  "225": $spacing-225,
  "250": $spacing-250,
  "275": $spacing-275,
  "300": $spacing-300,
  "325": $spacing-325,
  "350": $spacing-350,
  "375": $spacing-375,
  "400": $spacing-400,
  "425": $spacing-425,
  "450": $spacing-450,
  "475": $spacing-475,
  "500": $spacing-500,
  "525": $spacing-525,
  "550": $spacing-550,
  "575": $spacing-575,
  "600": $spacing-600,
  "625": $spacing-625,
  "650": $spacing-650,
  "675": $spacing-675,
  "700": $spacing-700,
  "725": $spacing-725,
  "750": $spacing-750,
  "775": $spacing-775,
  "800": $spacing-800,
) !default;

$line-height-10: 1;
$line-height-11: 1.1;
$line-height-12: 1.2;
$line-height-13: 1.3;
$line-height-14: 1.4;
$line-height-15: 1.5;
$line-height-16: 1.6;
$line-height-17: 1.7;
$line-height-18: 1.8;
$line-height-19: 1.9;
$line-height-20: 2;

$line-height-theme: ("10": $line-height-10,
  "11": $line-height-11,
  "12": $line-height-12,
  "13": $line-height-13,
  "14": $line-height-14,
  "15": $line-height-15,
  "16": $line-height-16,
  "17": $line-height-17,
  "18": $line-height-18,
  "19": $line-height-19,
  "20": $line-height-20,
) !default;

$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;

$font-weight-theme: ("100": 100,
  "200": 200,
  "300": 300,
  "400": 400,
  "500": 500,
  "600": 600,
  "700": 700,
  "800": 800,
  "900": 900,
) !default;

$list-style-disc: disc;
$list-style-decimal: decimal;
$list-style-theme: ("disc": $list-style-disc,
  "decimal": $list-style-decimal,
) !default;

$list-style-position-inside: inside;
$list-style-position-outside: outside;
$list-style-position-theme: ("inside": $list-style-position-inside,
  "outside": $list-style-position-outside,
) !default;