//layout .l-{className}
html.js-menuOpened {
	height: 100%;
	// overflow: hidden;
	// padding-right: 17px;
}

.l- {
	&header {
		width: 100%;
		position: absolute;
		top: 0;
		z-index: 1041;

		&-menu {
			width: 100%;
			position: absolute;
			top: 0;


			&-btn {
				position: fixed;
				top: 0;
				right: 0;
				z-index: 1001;
				width: 60px;
				height: 60px;
				background-color: $primary;
				@include min-sm {
					width: 80px;
					height: 80px;
				}
				@include min-md {
					width: 100px;
					height: 100px;
				}

				&-bar {
					position: absolute;
					transform: translate(-50%, -50%);
					top: 50%;
					left: 50%;
					// background-color: red;
					width: 70%;
					height: 40%;
					margin-top: -3px;

					&--default {
						position: absolute;
						background-color: $white;
						width: 100%;
						height: 4px;
						border-radius: 25px;
						transition: all ease-in-out 0.3s;

						@include min-md {
							height: 6px;
						}
					}

					&--top {
						right: 0;
						top: 0;
					}

					&--middle {
						right: 0;
						top: 50%;
						width: 80%;
					}

					&--bottom {
						right: 0;
						top: 100%;
						width: 60%;
					}

					&:hover {

						.l-header-menu-btn-bar--top,
						.l-header-menu-btn-bar--middle,
						.l-header-menu-btn-bar--bottom {
							width: 100%;
						}
					}

					&.js-menuOpened {
						.l-header-menu-btn-bar--top {
							width: 100%;
							top: 50%;
							transform: rotate(45deg);
						}

						.l-header-menu-btn-bar--middle {
							width: 100%;
							opacity: 0;
						}

						.l-header-menu-btn-bar--bottom {
							width: 100%;
							top: 50%;
							transform: rotate(-45deg);
						}
					}
				}
			}

			&-content {
				width: 100%;
				height: 100vh;
				position: fixed;
				z-index: 1;
				transition: all ease-in-out 0.3s;
				right: calc(-100% - 25px);

				@include min-md {
					width: 400px;
					right: -400px;
				}


				&.js-menuOpened {
					right: 0;
				}

				&::before {
					content: "";
					background-color: $primary;
					position: absolute;
					width: 100%;
					top: 0;
					bottom: 0;
					right: 0;
					opacity: 0.9;

					@include min-md {
						width: 400px;
					}
				}


				ul {
					margin-top: 100px;
					text-align: center;

					a {
						display: block;
						padding: 1rem;
						color: $white;
						font-size: $font-26;
						position: relative;
						font-weight: bold;
						letter-spacing: .25rem;

						&::before {
							content: "";
							height: 1px;
							background-color: lighten($color: $primary, $amount: 20%);
							width: 50px;
							position: absolute;
							transform: translateX(-50%);
							left: 50%;
							bottom: 0;
						}
					}
				}

			}
		}

		&-social {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 60px;
			@include min-sm {
				height: 80px;
			}
			@include min-md {
				height: 100px;
			}

			a {
				display: block;
			}

			img {
				width: 50px;
				margin: 1rem;
				transition: transform ease-in-out 0.3s;

				&:hover {
					transform: scale(1.2);
				}
			}
		}

		&-logo {
			position: relative;

			img {
				width: 260px;
			}
		}
	}
}