//RWD mixin 設定
//Boostrap的邏輯是走min-width的
@mixin min-xl {
	@media (min-width: $breakpoint-xl) {
		@content;
	}
}

@mixin min-lg {
	@media (min-width: $breakpoint-lg) {
		@content;
	}
}

@mixin min-md {
	@media (min-width: $breakpoint-md) {
		@content;
	}
}

@mixin min-sm {
	@media (min-width: $breakpoint-sm) {
		@content;
	}
}

//但是只想要手機版吃到設定，不想寫多寫電腦版的時候可以偷用max-width
@mixin max-xl {
	@media (max-width: $breakpoint-xl - 1px) {
		@content;
	}
}

@mixin max-lg {
	@media (max-width: $breakpoint-lg - 1px) {
		@content;
	}
}

@mixin max-md {
	@media (max-width: $breakpoint-md - 1px) {
		@content;
	}
}

@mixin max-sm {
	@media (max-width: $breakpoint-sm - 1px) {
		@content;
	}
}

//針對小螢幕手機的修正
@mixin max-380 {
	@media (max-width: 380px) {
		@content;
	}
}
@mixin max-400 {
	@media (max-width: 400px) {
		@content;
	}
}

// ----- PC列印樣式修正
@mixin screenmin($res-min) {
	@media screen and (min-width: $res-min) and (all) {
		@content;
	}

	@media print {
		@content;
	}
}
