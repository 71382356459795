// vendor 第三方元件修改
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: 0;
    border-radius: 0;

    &:hover {
        opacity: 0.8;
    }
}

.card-header {
    padding: 0;
    margin-bottom: 0;
    background-color: #0070ff;
    border-bottom: 1px solid #0070ff;
}

.btn-link {
    font-weight: 400;
    color: $white;
    text-decoration: none;

    &:hover {
        color: #ffffff;
        text-decoration: none;
    }
}

.collapse {
    overflow: hidden;
    height: 0;
    max-height: 0px;
    transition: all ease-in-out 0.5s;

    &:not(.show) {
        display: block;
    }

    &.show {
        max-height: 2000px;
        height: auto;
    }
}

.collapsed{
    .p-index-qa-accordion-btn-bar-top{
        transform: translate(-50%, -50%) rotate(90deg);
    }
}
.card-body{
    font-size: $font-18;
    text-align: justify;
    @include min-sm{
        font-size: $font-22;
    }
}