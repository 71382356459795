@keyframes scroll {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(10px);
    }
}

@keyframes txtBar {
    from {
        transform: translateX(100%)
    }

    to {
        transform: translateX(-100%)
    }
}

@keyframes txtBar2 {
    from {
        transform: translateX(0%)
    }

    to {
        transform: translateX(-200%)
    }
}