@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;500;700;900&display=swap");
@keyframes scroll {
  0% {
    transform: translateY(0px); }
  100% {
    transform: translateY(10px); } }

@keyframes txtBar {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(-100%); } }

@keyframes txtBar2 {
  from {
    transform: translateX(0%); }
  to {
    transform: translateX(-200%); } }

.u-border-color-promary {
  border-color: #28abf8; }

html {
  height: 100%; }

body {
  font-family: 'Noto Sans TC', sans-serif;
  height: 100%; }

img {
  pointer-events: none; }

ul {
  list-style: none;
  padding-left: 0; }

button {
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 151, 252, 0.3); }
  @media (min-width: 768px) {
    button {
      -webkit-tap-highlight-color: transparent; } }
  button:focus {
    outline: 0; }

a {
  -webkit-tap-highlight-color: rgba(0, 151, 252, 0.3); }
  @media (min-width: 768px) {
    a {
      -webkit-tap-highlight-color: transparent; } }
  a:hover {
    text-decoration: none; }

#wrapper {
  overflow-x: hidden; }

body.js-useMouse button:focus,
body.js-useMouse a:focus,
body.js-useMouse select:focus,
body.js-useMouse input:focus {
  outline: none; }

html.js-menuOpened {
  height: 100%; }

.l-header {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1041; }
  .l-header-menu {
    width: 100%;
    position: absolute;
    top: 0; }
    .l-header-menu-btn {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1001;
      width: 60px;
      height: 60px;
      background-color: #28abf8; }
      @media (min-width: 576px) {
        .l-header-menu-btn {
          width: 80px;
          height: 80px; } }
      @media (min-width: 768px) {
        .l-header-menu-btn {
          width: 100px;
          height: 100px; } }
      .l-header-menu-btn-bar {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 70%;
        height: 40%;
        margin-top: -3px; }
        .l-header-menu-btn-bar--default {
          position: absolute;
          background-color: #fff;
          width: 100%;
          height: 4px;
          border-radius: 25px;
          transition: all ease-in-out 0.3s; }
          @media (min-width: 768px) {
            .l-header-menu-btn-bar--default {
              height: 6px; } }
        .l-header-menu-btn-bar--top {
          right: 0;
          top: 0; }
        .l-header-menu-btn-bar--middle {
          right: 0;
          top: 50%;
          width: 80%; }
        .l-header-menu-btn-bar--bottom {
          right: 0;
          top: 100%;
          width: 60%; }
        .l-header-menu-btn-bar:hover .l-header-menu-btn-bar--top,
        .l-header-menu-btn-bar:hover .l-header-menu-btn-bar--middle,
        .l-header-menu-btn-bar:hover .l-header-menu-btn-bar--bottom {
          width: 100%; }
        .l-header-menu-btn-bar.js-menuOpened .l-header-menu-btn-bar--top {
          width: 100%;
          top: 50%;
          transform: rotate(45deg); }
        .l-header-menu-btn-bar.js-menuOpened .l-header-menu-btn-bar--middle {
          width: 100%;
          opacity: 0; }
        .l-header-menu-btn-bar.js-menuOpened .l-header-menu-btn-bar--bottom {
          width: 100%;
          top: 50%;
          transform: rotate(-45deg); }
    .l-header-menu-content {
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: 1;
      transition: all ease-in-out 0.3s;
      right: calc(-100% - 25px); }
      @media (min-width: 768px) {
        .l-header-menu-content {
          width: 400px;
          right: -400px; } }
      .l-header-menu-content.js-menuOpened {
        right: 0; }
      .l-header-menu-content::before {
        content: "";
        background-color: #28abf8;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0.9; }
        @media (min-width: 768px) {
          .l-header-menu-content::before {
            width: 400px; } }
      .l-header-menu-content ul {
        margin-top: 100px;
        text-align: center; }
        .l-header-menu-content ul a {
          display: block;
          padding: 1rem;
          color: #fff;
          font-size: 1.625rem;
          position: relative;
          font-weight: bold;
          letter-spacing: .25rem; }
          .l-header-menu-content ul a::before {
            content: "";
            height: 1px;
            background-color: #8bd2fb;
            width: 50px;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            bottom: 0; }
  .l-header-social {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px; }
    @media (min-width: 576px) {
      .l-header-social {
        height: 80px; } }
    @media (min-width: 768px) {
      .l-header-social {
        height: 100px; } }
    .l-header-social a {
      display: block; }
    .l-header-social img {
      width: 50px;
      margin: 1rem;
      transition: transform ease-in-out 0.3s; }
      .l-header-social img:hover {
        transform: scale(1.2); }
  .l-header-logo {
    position: relative; }
    .l-header-logo img {
      width: 260px; }

.l-footer {
  color: #7a7878;
  font-size: 0.75rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid #656565;
  background-color: #f0f0f0; }

.c-btn-arrow {
  margin: 0 auto;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 2px solid #28abf8;
  position: relative;
  transition: background-color ease-in-out 0.3s; }
  .c-btn-arrow::before {
    content: "";
    border-width: 2px;
    border-style: solid;
    border-color: transparent transparent #28abf8 #28abf8;
    width: 26px;
    height: 26px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    top: 50%;
    left: 50%;
    transition: all ease-in-out 0.3s; }
  .c-btn-arrow::after {
    content: "";
    width: 36px;
    height: 2px;
    background-color: #28abf8;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: all ease-in-out 0.3s; }
  .c-btn-arrow:hover {
    background-color: #28abf8; }
    .c-btn-arrow:hover::before {
      border-color: transparent transparent #fff #fff; }
    .c-btn-arrow:hover::after {
      background-color: #fff; }

.c-btn-modal {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #ffec08;
  position: relative;
  font-size: 4rem;
  font-weight: 700;
  line-height: 95px;
  text-align: center;
  transition: transform ease-in-out 0.3s; }
  .c-btn-modal:hover {
    transform: scale(1.1); }

.c-btn-kv {
  font-weight: 700;
  font-size: 1.625rem;
  color: #0044a0;
  letter-spacing: 4px;
  background-color: #ffec08;
  border-radius: 50px;
  padding: 0.3rem 1.5rem 0.3rem 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  box-shadow: 0px 8px 0px #0044a0;
  transition: all ease-in-out 0.3s;
  position: relative;
  z-index: 5; }
  .c-btn-kv:hover {
    transform: scale(1.1); }
  .c-btn-kv-icon {
    margin-right: 0.5rem; }
    .c-btn-kv-icon img {
      width: 45px; }

.c-btn-scroll {
  color: #ffec08;
  letter-spacing: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -60px; }
  @media (min-width: 576px) {
    .c-btn-scroll {
      top: auto; } }
  .c-btn-scroll:hover {
    color: #ffec08; }
  .c-btn-scroll-arrow {
    width: 35px;
    height: 15px;
    display: block;
    position: relative;
    animation-name: scroll;
    animation-duration: 1s;
    animation-iteration-count: infinite; }
    .c-btn-scroll-arrow-ontolo {
      width: 35px;
      height: 35px;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%; }
      .c-btn-scroll-arrow-ontolo::before {
        content: "";
        width: 35px;
        height: 35px;
        border-width: 3px;
        border-style: solid;
        border-color: transparent transparent #ffec08 #ffec08;
        transform: rotate(-45deg);
        position: absolute; }

.c-btn-download {
  font-weight: 900;
  font-size: 1.25rem;
  color: #0044a0;
  background-color: #ffec08;
  border-radius: 50px;
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  box-shadow: 0px 8px 0px #0044a0;
  transition: all ease-in-out 0.3s;
  position: relative;
  top: -2.5rem; }
  @media (min-width: 576px) {
    .c-btn-download {
      font-size: 1.625rem; } }
  .c-btn-download:hover {
    transform: scale(1.1); }
  .c-btn-download-icon {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .c-btn-download-icon img {
      width: 80%; }
    @media (min-width: 576px) {
      .c-btn-download-icon {
        width: 60px;
        height: 60px; } }

.c-btn-music {
  display: flex;
  justify-content: center;
  align-items: center; }
  .c-btn-music-arrow {
    line-height: 0;
    margin-right: 5px; }
    .c-btn-music-arrow img {
      width: 35px; }
  .c-btn-music-line {
    width: 100%;
    height: 1px;
    background-color: #0044a0; }

.c-btn-float {
  position: fixed;
  right: 0;
  bottom: 3%;
  z-index: 990;
  opacity: 0;
  transition: all ease-in-out 0.3s; }
  .c-btn-float img {
    width: 110px; }
    @media (min-width: 576px) {
      .c-btn-float img {
        width: 160px; } }
  .c-btn-float:hover {
    transform: scale(1.1); }
  .c-btn-float.js-show {
    opacity: 1; }

.c-card {
  border: 5px solid #448bff;
  overflow: hidden;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.7); }

.c-skewCard {
  position: relative; }
  .c-skewCard::before {
    content: "";
    width: 95%;
    height: 100%;
    background-color: #0070ff;
    position: absolute;
    transform: translate(-50%, -50%) rotate(-5deg) skew(-5deg);
    top: 50%;
    left: 50%; }
  .c-skewCard-content {
    position: relative;
    background-color: #fff;
    border: 5px solid #0070ff; }
    .c-skewCard-content-title {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      bottom: -2rem; }

.p-index-kv {
  background-image: url("../img/index/bg_kvMovie.jpg");
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .p-index-kv .embed-responsive-16by9 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .p-index-kv .l-header-social {
    z-index: 10; }
  .p-index-kv::before {
    content: "";
    background-image: url("../img/index/bg_kvMask.png");
    background-repeat: repeat;
    background-size: auto;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; }
  .p-index-kv-video {
    object-fit: cover; }
  .p-index-kv-wrapper {
    position: relative;
    z-index: 3;
    padding-top: 6rem; }
  .p-index-kv-logo {
    text-align: center;
    margin-bottom: 2rem; }
    .p-index-kv-logo img {
      width: 130px; }
      @media (min-width: 576px) {
        .p-index-kv-logo img {
          width: 185px; } }
  .p-index-kv-img {
    text-align: center;
    margin-bottom: 2rem; }
    .p-index-kv-img img {
      width: 260px; }
      @media (min-width: 576px) {
        .p-index-kv-img img {
          width: 600px; } }
  .p-index-kv-mascot {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 2rem; }
    @media (min-width: 768px) {
      .p-index-kv-mascot {
        padding-top: 0; } }
    .p-index-kv-mascot img {
      width: 120px; }

.p-index-award {
  background-image: url("../img/index/bg_award.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: #dfeffc;
  position: relative; }
  .p-index-award-bg {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;
    width: 250%; }
    @media (min-width: 576px) {
      .p-index-award-bg {
        width: 100%; } }
  .p-index-award-great {
    color: #08254d; }
    .p-index-award-great-title {
      text-align: center;
      margin-bottom: 2rem; }
      .p-index-award-great-title img {
        width: 530px; }
    .p-index-award-great-secTitle {
      text-align: center;
      font-size: 2rem;
      font-weight: 900;
      letter-spacing: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem; }
      .p-index-award-great-secTitle img {
        width: 40px; }
        @media (min-width: 576px) {
          .p-index-award-great-secTitle img {
            width: 50px; } }
      @media (min-width: 576px) {
        .p-index-award-great-secTitle {
          font-size: 2.5rem; } }
    .p-index-award-great-txt {
      text-align: center;
      font-weight: 500;
      letter-spacing: 2px;
      line-height: 2; }
      @media (min-width: 576px) {
        .p-index-award-great-txt {
          font-size: 1.25rem; } }
    .p-index-award-great-img {
      text-align: center; }
      .p-index-award-great-img img {
        width: 300px; }
        @media (min-width: 576px) {
          .p-index-award-great-img img {
            width: 366px; } }
    .p-index-award-great-dashed {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem; }
      .p-index-award-great-dashed img {
        width: 590px; }
    .p-index-award-great-strong {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700; }
  .p-index-award-music-img {
    background-image: url("../img/index/bg_kvMovie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 2px solid #fff;
    position: relative;
    padding-top: 4rem;
    padding-bottom: 5rem;
    padding-left: 2rem;
    padding-right: 2rem; }
    .p-index-award-music-img::before {
      content: "";
      background-color: #0070ff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.4; }
  .p-index-award-music-icon {
    margin-bottom: 2rem; }
    .p-index-award-music-icon img {
      width: 140px; }
  .p-index-award-music-tag img {
    width: 210px; }
    @media (min-width: 576px) {
      .p-index-award-music-tag img {
        width: 230px; } }
  .p-index-award-music-subtitle {
    color: #fff;
    font-size: 1.5rem;
    letter-spacing: 2px;
    margin-bottom: 0; }
  .p-index-award-music-title {
    color: #fff;
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: 4px;
    margin-bottom: 0; }
  .p-index-award-movie-title {
    background-color: #ffec08;
    font-weight: 900;
    color: #0044a0;
    display: inline-block;
    padding: 0.25rem 0.5rem;
    transform: rotate(-5deg) skew(-5deg);
    box-shadow: 5px 5px 0px #0044a0;
    letter-spacing: 2px;
    white-space: nowrap; }
  .p-index-award-movie-txt {
    color: #0044a0;
    font-size: 1.125rem;
    letter-spacing: 2px;
    margin-bottom: 0.25rem; }
  .p-index-award-movie-tag {
    color: #0044a0;
    font-weight: 900;
    font-size: 1.125rem;
    letter-spacing: 2px;
    background-color: #fff;
    display: inline-block;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    display: inline-block;
    align-self: baseline; }
  .p-index-award-movie-play {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    transition: transform ease-in-out 0.3s; }
    .p-index-award-movie-play img {
      width: 60px; }
  .p-index-award-movie .c-skewCard-content:hover .p-index-award-movie-play {
    transform: translate(-50%, -50%) scale(1.2); }

.p-index-news {
  background-image: url("../img/index/bg_news.jpg");
  background-repeat: no-repeat;
  background-size: 300%;
  background-position: center 0%;
  padding-top: 6rem;
  padding-bottom: 6rem; }
  @media (min-width: 576px) {
    .p-index-news {
      background-size: cover; } }
  .p-index-news-wrapper {
    background-color: rgba(175, 224, 255, 0.8);
    border: 6px solid #fff; }
  .p-index-news-title {
    text-align: center;
    margin-bottom: 2rem; }
    .p-index-news-title img {
      width: 277px; }
  .p-index-news-card {
    cursor: pointer; }
    .p-index-news-card-title {
      margin-bottom: 0;
      background-color: #0095f5;
      color: #fff;
      font-size: 1.25rem;
      padding: 1rem;
      padding-left: 1rem; }
      .p-index-news-card-title::before {
        content: ">";
        margin-right: 0.5rem; }
  .p-index-news-modal {
    color: #0070ff; }
    .p-index-news-modal-title {
      font-weight: bold; }
    .p-index-news-modal-txt {
      margin-bottom: 0; }

.p-index-product {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #bfe3ff;
  position: relative; }
  .p-index-product .c-skewCard-content {
    background: linear-gradient(#fff, #bfe3ff); }
  .p-index-product-deco {
    position: absolute; }
    .p-index-product-deco-top {
      bottom: 100%;
      margin-bottom: -3px; }
      @media (min-width: 576px) {
        .p-index-product-deco-top {
          margin-bottom: -1px; } }
    .p-index-product-deco-bottom {
      top: 100%;
      transform: scaleY(-1);
      margin-top: -3px; }
      @media (min-width: 576px) {
        .p-index-product-deco-bottom {
          margin-top: -1px; } }
    .p-index-product-deco-txtBar .css-first {
      animation: txtBar 20s linear infinite; }
      .p-index-product-deco-txtBar .css-first img {
        max-height: 122px; }
    .p-index-product-deco-txtBar .css-second {
      animation: txtBar2 20s linear infinite;
      animation-delay: -10s; }
      .p-index-product-deco-txtBar .css-second img {
        max-height: 122px; }
    .p-index-product-deco-txtBar.css-toLeft {
      display: flex;
      top: 3%; }
      @media (min-width: 576px) {
        .p-index-product-deco-txtBar.css-toLeft {
          top: 10%; } }
    .p-index-product-deco-txtBar.css-toRight {
      display: flex;
      bottom: 3%; }
      @media (min-width: 576px) {
        .p-index-product-deco-txtBar.css-toRight {
          bottom: 10%; } }
      .p-index-product-deco-txtBar.css-toRight .css-first {
        animation-direction: reverse; }
      .p-index-product-deco-txtBar.css-toRight .css-second {
        animation-direction: reverse; }
  .p-index-product-title {
    margin-bottom: 2rem;
    display: inline-block;
    position: relative; }
    .p-index-product-title::before, .p-index-product-title::after {
      content: "";
      height: 6px;
      background-color: #0070ff;
      width: 30%;
      position: absolute;
      transform: translateY(-50%) skew(-30deg);
      top: 50%;
      display: none; }
      @media (min-width: 768px) {
        .p-index-product-title::before, .p-index-product-title::after {
          display: block; } }
    .p-index-product-title::before {
      left: -6rem; }
    .p-index-product-title::after {
      right: -6rem; }
    .p-index-product-title img {
      width: 266px; }
  .p-index-product-img {
    padding: 3rem; }
    @media (min-width: 576px) {
      .p-index-product-img {
        padding: 5rem; } }
    @media (min-width: 992px) {
      .p-index-product-img {
        padding: 0; } }
  .p-index-product-content .slick-prev:before,
  .p-index-product-content .slick-next:before {
    color: #0095f5; }
  .p-index-product-content .slick-prev {
    left: -2%; }
  .p-index-product-content .slick-next {
    right: -2%; }

.p-index-about {
  background-image: url("../img/index/bg_about.jpg");
  background-repeat: no-repeat;
  background-size: 500%;
  background-position: center 0%;
  padding-top: 6rem;
  padding-bottom: 6rem; }
  @media (min-width: 576px) {
    .p-index-about {
      background-size: cover; } }
  .p-index-about-title {
    text-align: center;
    margin-bottom: 2rem; }
    .p-index-about-title img {
      width: 277px; }
  .p-index-about-txt {
    font-size: 1.125rem;
    letter-spacing: 2px;
    line-height: 2;
    font-weight: 300; }
  .p-index-about-img img {
    width: 120px; }
    @media (min-width: 576px) {
      .p-index-about-img img {
        width: 172px; } }

.p-index-qa {
  background-image: url("../img/index/bg_qa.png");
  background-repeat: repeat;
  background-size: 400px;
  background-position: center;
  background-color: #edf6fd;
  padding-top: 6rem;
  padding-bottom: 6rem; }
  .p-index-qa-accordion-title {
    border-right: 3px solid #fff;
    font-size: 1.375rem; }
    @media (min-width: 576px) {
      .p-index-qa-accordion-title {
        font-size: 1.5rem; } }
  .p-index-qa-accordion-btn {
    display: block;
    width: 43px;
    height: 43px;
    position: relative; }
    .p-index-qa-accordion-btn-bar {
      width: 80%;
      height: 3px;
      background-color: #fff;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%; }
      .p-index-qa-accordion-btn-bar-top {
        transition: transform ease-in-out 0.3s; }

.p-index-contactUs-title {
  margin-bottom: 1rem;
  text-align: center; }
  .p-index-contactUs-title img {
    width: 212px; }

.p-index-contactUs-content {
  padding-top: 1.5rem;
  border-top: 1px solid #28abf8; }
  @media (min-width: 576px) {
    .p-index-contactUs-content {
      display: inline-block; } }
  .p-index-contactUs-content-txt {
    display: flex;
    color: #28abf8;
    font-size: 1.625rem;
    font-weight: 700;
    word-break: break-all; }
    .p-index-contactUs-content-txt img {
      align-self: start;
      width: 35px;
      padding-top: 8px; }

.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px; }

.p-products-wrapper {
  background-image: url("../img/products/bg_ice.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  padding-top: calc(60px + 1rem); }
  @media (min-width: 576px) {
    .p-products-wrapper {
      padding-top: calc(80px + 1rem); } }
  @media (min-width: 768px) {
    .p-products-wrapper {
      padding-top: calc(100px + 1rem); } }

.p-products-list-classTitle {
  text-align: center; }
  .p-products-list-classTitle img {
    width: 450px; }

.p-products-list-title {
  text-align: center;
  width: fit-content;
  margin: 0 auto; }
  .p-products-list-title h3 {
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    margin-bottom: 0;
    font-size: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    @media (min-width: 768px) {
      .p-products-list-title h3 {
        font-size: 1.625rem; } }

.p-products-list-img {
  margin-bottom: 1rem; }
  .p-products-list-img img {
    transition: transform ease-in-out 0.3s; }
    .p-products-list-img img:hover {
      transform: scale(1.1) rotateZ(15deg); }

.p-products-inpage-title {
  color: #28abf8;
  margin-bottom: 1rem; }
  .p-products-inpage-title h3 {
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #28abf8; }
    @media (min-width: 768px) {
      .p-products-inpage-title h3 {
        font-size: 2.875rem;
        display: inline-block; } }
  .p-products-inpage-title p {
    font-weight: 700; }
    @media (min-width: 768px) {
      .p-products-inpage-title p {
        font-size: 1.5rem; } }

.p-products-inpage-tag {
  background-color: #28abf8;
  font-weight: 700;
  color: #fff;
  width: 130px; }
  @media (min-width: 768px) {
    .p-products-inpage-tag {
      width: 200px; } }
  .p-products-inpage-tag h4 {
    text-align: center;
    font-size: 1rem; }
    @media (min-width: 768px) {
      .p-products-inpage-tag h4 {
        width: 200px;
        font-size: 1.375rem; } }

.p-products-inpage-content {
  margin-bottom: 1rem; }

.p-products-inpage-dealer {
  width: 50px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.slick-dots i {
  color: #fff;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  font-size: 1.25rem; }

.slick-dots .fa-caret-left {
  left: 0; }

.slick-dots .fa-caret-right {
  right: 0; }

.slick-dots li {
  margin: 0 2px; }

.slick-dots li button:before {
  opacity: 1;
  color: #fff; }

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0095f5; }

.slick-prev, .slick-next {
  z-index: 5; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 1.25rem;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -5%; }

.slick-next {
  right: -5%; }

.slick-prev:before {
  content: '◀'; }

.slick-next:before {
  content: '▶'; }

.p-index-product-img img {
  transition: all ease-in-out 0.5s; }

.slick-current .p-index-product-img img {
  transform: scale(1.3); }

.slick-cloned.slick-center .p-index-product-img img {
  transform: scale(1.3); }

@media (max-width: 991px) {
  .slick-slide.slick-cloned.js-active .p-index-product-img img {
    transform: scale(1.3); } }

.modal-backdrop {
  background-color: #6dc6ff; }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 0;
  border-radius: 0;
  outline: 0; }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 0;
  border-radius: 0; }
  .card:hover {
    opacity: 0.8; }

.card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #0070ff;
  border-bottom: 1px solid #0070ff; }

.btn-link {
  font-weight: 400;
  color: #fff;
  text-decoration: none; }
  .btn-link:hover {
    color: #ffffff;
    text-decoration: none; }

.collapse {
  overflow: hidden;
  height: 0;
  max-height: 0px;
  transition: all ease-in-out 0.5s; }
  .collapse:not(.show) {
    display: block; }
  .collapse.show {
    max-height: 2000px;
    height: auto; }

.collapsed .p-index-qa-accordion-btn-bar-top {
  transform: translate(-50%, -50%) rotate(90deg); }

.card-body {
  font-size: 1.125rem;
  text-align: justify; }
  @media (min-width: 576px) {
    .card-body {
      font-size: 1.375rem; } }

@-moz-document url-prefix() {}
