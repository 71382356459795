//layout .l-{className}
.l- {
	&footer {
		color: #7a7878;
		font-size: $font-12;
		padding-top: 2rem;
		padding-bottom: 2rem;
		border-top: 1px solid #656565;
		background-color: #f0f0f0;
	}
}
