//page .p-{className}
.p- {
    &index {
        &-kv {
            background-image: url("../img/index/bg_kvMovie.jpg");
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .embed-responsive-16by9 {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .l-header-social {
                z-index: 10;
            }

            &::before {
                content: "";
                background-image: url("../img/index/bg_kvMask.png");
                background-repeat: repeat;
                background-size: auto;
                background-position: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
            }

            &-video {
                object-fit: cover;
            }

            &-wrapper {
                position: relative;
                z-index: 3;
                padding-top: 6rem;
            }

            &-logo {
                text-align: center;
                margin-bottom: 2rem;

                img {
                    width: 130px;
                    @include min-sm{
                        width: 185px;
                    }
                }
            }

            &-img {
                text-align: center;
                margin-bottom: 2rem;

                img {
                    width: 260px;
                    @include min-sm{
                        width: 600px;
                    }
                }
            }

            &-mascot {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                padding-top: 2rem;
                // padding-bottom: 5rem;

                @include min-md {
                    padding-top: 0;
                }

                img {
                    width: 120px;
                }
            }
        }

        &-award {
            background-image: url("../img/index/bg_award.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
            background-color: #dfeffc;
            position: relative;

            &-bg {
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                bottom: 0;
                width: 250%;

                @include min-sm {
                    width: 100%;
                }
            }

            &-great {
                color: #08254d;

                &-title {
                    text-align: center;
                    margin-bottom: 2rem;

                    img {
                        width: 530px;
                    }
                }

                &-secTitle {
                    text-align: center;
                    font-size: $font-32;
                    font-weight: 900;
                    letter-spacing: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1rem;

                    img {
                        width: 40px;

                        @include min-sm {
                            width: 50px;
                        }
                    }

                    @include min-sm {
                        font-size: $font-40;
                    }
                }

                &-txt {
                    text-align: center;
                    font-weight: 500;
                    letter-spacing: 2px;
                    line-height: 2;

                    @include min-sm {
                        font-size: $font-20;
                    }
                }

                &-img {
                    text-align: center;

                    img {
                        width: 300px;

                        @include min-sm {
                            width: 366px;
                        }
                    }
                }

                &-dashed {
                    text-align: center;
                    margin-top: 2rem;
                    margin-bottom: 2rem;

                    img {
                        width: 590px;
                    }
                }

                &-strong {
                    text-align: center;
                    font-size: $font-24;
                    font-weight: 700;
                }
            }

            &-music {
                &-img {
                    background-image: url("../img/index/bg_kvMovie.jpg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    border: 2px solid $white;
                    position: relative;
                    padding-top: 4rem;
                    padding-bottom: 5rem;
                    padding-left: 2rem;
                    padding-right: 2rem;

                    &::before {
                        content: "";
                        background-color: $thrid;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: 0.4;
                    }
                }

                &-icon {
                    margin-bottom: 2rem;

                    img {
                        width: 140px;
                    }
                }

                &-tag {
                    img {
                        width: 210px;
                        @include min-sm{
                            width: 230px;
                        }
                    }
                }

                &-subtitle {
                    color: $white;
                    font-size: $font-24;
                    letter-spacing: 2px;
                    margin-bottom: 0;
                }

                &-title {
                    color: $white;
                    font-size: $font-30;
                    font-weight: 700;
                    letter-spacing: 4px;
                    margin-bottom: 0;
                }
            }

            &-movie {
                &-title {
                    background-color: $yellow;
                    font-weight: 900;
                    color: $secondary;
                    display: inline-block;
                    padding: 0.25rem 0.5rem;
                    transform: rotate(-5deg) skew(-5deg);
                    box-shadow: 5px 5px 0px $secondary;
                    letter-spacing: 2px;
                    white-space: nowrap;
                }

                &-txt {
                    color: $secondary;
                    font-size: $font-18;
                    letter-spacing: 2px;
                    margin-bottom: 0.25rem;
                }

                &-tag {
                    color: $secondary;
                    font-weight: 900;
                    font-size: $font-18;
                    letter-spacing: 2px;
                    background-color: $white;
                    display: inline-block;
                    padding-left: 0.25rem;
                    padding-right: 0.25rem;
                    display: inline-block;
                    align-self: baseline;
                }

                &-play {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                    transition: transform ease-in-out 0.3s;

                    img {
                        width: 60px;
                    }
                }

                .c-skewCard-content {
                    &:hover {
                        .p-index-award-movie-play {
                            transform: translate(-50%, -50%) scale(1.2);
                        }
                    }
                }
            }
        }

        &-news {
            background-image: url("../img/index/bg_news.jpg");
            background-repeat: no-repeat;
            background-size: 300%;
            background-position: center 0%;
            padding-top: 6rem;
            padding-bottom: 6rem;
            @include min-sm{
                background-size: cover;
            }
            &-wrapper {
                background-color: rgba($color: #afe0ff, $alpha: 0.8);
                border: 6px solid $white;
            }

            &-title {
                text-align: center;
                margin-bottom: 2rem;

                img {
                    width: 277px;
                }
            }

            &-card {
                cursor: pointer;

                &-title {
                    margin-bottom: 0;
                    background-color: #0095f5;
                    color: $white;
                    font-size: $font-20;
                    padding: 1rem;
                    padding-left: 1rem;

                    &::before {
                        content: ">";
                        margin-right: 0.5rem;
                    }
                }

            }

            &-modal {
                color: #0070ff;

                &-img {}

                &-title {
                    font-weight: bold;
                }

                &-txt {
                    margin-bottom: 0;
                }
            }
        }

        &-product {
            padding-top: 6rem;
            padding-bottom: 6rem;
            background-color: #bfe3ff;
            position: relative;

            .c-skewCard-content {
                background: linear-gradient($white, #bfe3ff);
            }

            &-deco {
                position: absolute;

                &-top {
                    bottom: 100%;
                    margin-bottom: -3px;
                    @include min-sm{
                        margin-bottom: -1px;
                    }
                }

                &-bottom {
                    top: 100%;
                    transform: scaleY(-1);
                    margin-top: -3px;
                    @include min-sm{
                        margin-top: -1px;
                    }
                }

                &-txtBar {
                    .css-first{
                        animation: txtBar 20s linear infinite;
                        img{
                            // width: 2500px;
                            max-height: 122px;
                        }
                    }
                    .css-second{
                        animation: txtBar2 20s linear infinite;
                        animation-delay: -10s;
                        img{
                            // width: 2500px;
                            max-height: 122px;
                        }
                    }
                    &.css-toLeft{
                        display: flex;
                        top: 3%;
                        @include min-sm{
                            top: 10%;
                        }
                    }
                    &.css-toRight{
                        display: flex;
                        bottom: 3%;
                        @include min-sm{
                            bottom: 10%;
                        }
                        .css-first{
                            animation-direction: reverse;
                        }
                        .css-second{
                            animation-direction: reverse;
                        }
                    }
                }

            }

            &-title {
                margin-bottom: 2rem;
                display: inline-block;
                position: relative;

                &::before,
                &::after {
                    content: "";
                    height: 6px;
                    background-color: #0070ff;
                    width: 30%;
                    position: absolute;
                    transform: translateY(-50%) skew(-30deg);
                    top: 50%;
                    display: none;

                    @include min-md {
                        display: block;
                    }
                }

                &::before {
                    left: -6rem;
                }

                &::after {
                    right: -6rem;
                }

                img {
                    width: 266px;
                }
            }

            &-img {
                padding: 3rem;

                @include min-sm {
                    padding: 5rem;
                }

                @include min-lg {
                    padding: 0;
                }
            }

            &-content {

                .slick-prev:before,
                .slick-next:before {
                    color: #0095f5;
                }

                .slick-prev {
                    left: -2%;
                }

                .slick-next {
                    right: -2%;
                }
            }
        }

        &-about {
            background-image: url("../img/index/bg_about.jpg");
            background-repeat: no-repeat;
            background-size: 500%;
            background-position: center 0%;
            padding-top: 6rem;
            padding-bottom: 6rem;
            @include min-sm{
                background-size: cover;
            }

            &-title {
                text-align: center;
                margin-bottom: 2rem;

                img {
                    width: 277px;
                }
            }

            &-txt {
                font-size: $font-18;
                letter-spacing: 2px;
                line-height: 2;
                font-weight: 300;
            }

            &-img {
                img {
                    width: 120px;
                    @include min-sm{
                        width: 172px;
                    }
                   
                }
            }
        }

        &-qa {
            background-image: url("../img/index/bg_qa.png");
            background-repeat: repeat;
            background-size: 400px;
            background-position: center;
            background-color: #edf6fd;
            padding-top: 6rem;
            padding-bottom: 6rem;

            &-accordion {
                &-title {
                    border-right: 3px solid $white;
                    font-size: $font-22;
                    @include min-sm{
                        font-size: $font-24;
                    }
                }

                &-btn {
                    display: block;
                    width: 43px;
                    height: 43px;
                    position: relative;
                    // background-color: red;

                    &-bar {
                        width: 80%;
                        height: 3px;
                        background-color: $white;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        top: 50%;
                        left: 50%;

                        &-top {
                            transition: transform ease-in-out 0.3s;
                            // transform: translate(-50%, -50%) rotate(90deg);
                        }
                    }
                }
            }
        }

        &-contactUs {
            &-title {
                margin-bottom: 1rem;
                text-align: center;

                img {
                    width: 212px;
                }
            }

            &-content {
                padding-top: 1.5rem;
                border-top: 1px solid $primary;

                @include min-sm {
                    display: inline-block;
                }

                &-txt {
                    display: flex;
                    // align-items: center;
                    color: $primary;
                    font-size: $font-26;
                    font-weight: 700;
                    word-break: break-all;

                    img {
                        align-self: start;
                        width: 35px;
                        padding-top: 8px;
                    }
                }
            }
        }
    }
}
.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
    opacity: 0;
    pointer-events: none;
    width: 5px;
}